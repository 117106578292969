import $ from 'jquery';
import 'sticky-kit/dist/sticky-kit';

const STICKY_CLASS = 'is-stuck';

export default class Sticky {
    constructor(selector = '.js-sticky') {
        this._$stickies = $(selector);

        if (this._$stickies.length === 0) {
            return;
        }

        this._$stickies.each((index, element) => {
            const $sticky = $(element);
            const options = $.extend({
                sticky_class: STICKY_CLASS // eslint-disable-line camelcase
            }, $sticky.data());

            $sticky.stick_in_parent(options).on('sticky_kit:bottom', (e) => {
                $(e.target).removeClass(STICKY_CLASS);
            }).on('sticky_kit:unbottom', (e) => {
                $(e.target).addClass(STICKY_CLASS);
            });
        });
    }
}
