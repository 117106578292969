/* eslint-disable */

import $ from 'jquery';

export default class ObjectViewers {
    constructor(selector = '.js-object-viewer') {
        this.$views = $(selector);

        if ( !this.$views.length ) {
            return;
        }

        this.setViewers();
    }

    setViewers() {
        this.$views.each((index, view) => {
            const $view = $(view);

            require.ensure([], () => {
                switch ($view.data('type')) {
                    case 'measure':
                        const MeasureDetailViewer = require('./measure-detail-viewer').default;

                        return new MeasureDetailViewer($view);
                    case 'element':
                        const ElementViewer = require('./element-viewer').default;

                        return new ElementViewer($view);
                }
            });
        });
    }
}
