export default {
    init: function () {
        this.form = document.querySelector('.js-community-create-topic')

        if (!this.form) {
            return
        }

        this.measureFields = this.form.querySelector(
            '.js-community-create-topic-measure-fields'
        )
        this.themes = this.form.querySelectorAll(
            '.js-community-create-topic-themes input[type="radio"]'
        )

        this.themes.forEach((theme) => {
            theme.addEventListener('change', this.validate.bind(this))
        })

        this.form.querySelector('input[type="radio"]:checked').value ===
        'measures'
            ? this.measureFields.classList.remove('is-hidden')
            : this.measureFields.classList.add('is-hidden')
    },

    validate(event) {
        const radio = event.target
        const isChecked = radio.checked
        const value = event.target.value

        if (value === 'measures' && isChecked) {
            this.measureFields.classList.remove('is-hidden')

            return
        }

        this.measureFields.classList.add('is-hidden')
    },
}
