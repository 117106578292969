import $ from 'jquery';

export default class DropdownHandler {
    constructor(selector = '.js-dropdown') {
        $(document).on('click', (e) => {
            const $target = $(e.target);

            if ( !$target.is('[for^="state"], [name^="state-"]') && !$target.closest(selector).length ) {
                $(selector).prev('[name^="state-"]').prop('checked', false);
            }
        });
    }
}
