import $ from 'jquery';

export default class ScrollTo {
    constructor(selector = '.js-scroll-to') {
        $(document).on('click', selector, this._handleClick.bind(this));
    }

    _handleClick(e) {
        e.preventDefault();

        const $target = $(e.currentTarget);
        const $to = $($target.attr('href'));
        if ( !$to.length ) {
            return;
        }

        $target.blur();
        $('html, body').stop().animate({
            scrollTop: $to.offset().top
        });
    }
}
