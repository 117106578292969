import $ from 'jquery';

export default {
    init() {
        const forms = $('.js-download-form');

        if (forms.length === 0) {
            return;
        }

        this.$fileUploadInput = forms.find('input[type="hidden"]');
        this.$fileUpload = $('.js-file-upload');

        forms.on('submit', this.handleSubmit.bind(this));
    },

    handleSubmit(e) {
        e.preventDefault();

        const $form = $(e.currentTarget);
        this.$title = $form.find('input[type="text"]');
        const title = this.$title.val();
        const file = this.$fileUploadInput.val();
        const $submitButton = $('.js-download-submit');

        $('.js-download-success').remove();

        if (this.hasErrors($form, title, file)) {
            return;
        }

        $submitButton.addClass('btn--loading');

        $.ajax({
            method: 'POST',
            url: $form.attr('action'),
            data: JSON.stringify({
                title,
                file
            })
        }).done((response) => {
            const row = $(response);
            let $table = $('.js-download-table');

            if ($table.length === 0) {
                $table = this.createTable();

                $form.prepend($table);
            }

            $table.find('tbody').append(row);
            $submitButton.removeClass('btn--loading');

            $('.js-file-upload-cancel').click();
            $form.trigger('reset');
        }).fail((err) => {
            console.error(err);
            alert('Er ging iets mis tijdens het verzenden van de download. Probeer het later nog eens.');
        });
    },

    createTable() {
        return $(`
            <table class="table js-download-table">
                <thead>
                <tr>
                    <th>Titel</th>
                    <th>Bestandsnaam</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    
                </tbody>
            </table>
        `);
    },

    hasErrors($form, title, file) {
        let hasErrors = false;

        $form.find('.has-error').removeClass('has-error');
        $form.find('.form-help-message').remove();

        if (title === '') {
            this.$title.after(this.getErrorMessage());
            this.$title.closest('.form-group').addClass('has-error');

            hasErrors = true;
        }

        if (file === '') {
            this.$fileUpload.after(this.getErrorMessage());
            this.$fileUpload.closest('.form-group').addClass('has-error');

            hasErrors = true;
        }

        return hasErrors;
    },

    getErrorMessage() {
        return $('<div class="form-help-message">Deze waarde mag niet leeg zijn.</div>');
    }
};
