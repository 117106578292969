const body = document.querySelector('body');

export const lockBody = () => {
    const rect = body.getBoundingClientRect();
    body.classList.add('is-locked');
    body.setAttribute('style', `top: ${rect.top}px`);
};

export const unlockBody = () => {
    const rect = body.getBoundingClientRect();
    body.classList.remove('is-locked');
    body.removeAttribute('style');
    window.scrollTo(0, Math.abs(rect.top));
};
