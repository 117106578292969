export default {
    init() {
        const elements = document.querySelectorAll('.js-modal-trigger');

        if (elements.length === 0) {
            return;
        }

        elements.forEach((element) => {
            element.addEventListener('click', this.openModal.bind(this));
        });

        document.querySelectorAll('.js-modal-trigger-close').forEach((closeTrigger) => {
            closeTrigger.addEventListener('click', () => {
                document.querySelector('.modal-is-open').classList.remove('modal-is-open');
            });
        });
    },

    openModal(event) {
        event.preventDefault();

        const button = event.currentTarget;
        const data = button.dataset;
        const modal = data.parent ? document.querySelector(data.parent).querySelector('.modal') : button.nextElementSibling;
        const backdrop = modal.querySelector('.modal-backdrop');
        const closeButton = modal.querySelector('.js-modal-trigger-close');

        button.classList.add('is-open');

        if (data.parent) {
            button.closest(data.parent).classList.add('modal-is-open');
        }

        backdrop.addEventListener('click', () => {
            button.classList.remove('is-open');
            this.removeParentClass(button);
        });

        closeButton.addEventListener('click', () => {
            button.classList.remove('is-open');
            this.removeParentClass(button);
        });
    },

    removeParentClass(button) {
        const data = button.dataset;

        if (!data.parent) {
            return;
        }

        button.closest(data.parent).classList.remove('modal-is-open');
    }
};
