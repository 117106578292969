import $ from 'jquery';

export default class ConfirmHandler {
    constructor(selector = '.js-confirm') {
        $('body').on('click', selector, this.handleClick.bind(this));
    }

    handleClick(e) {
        return confirm($(e.currentTarget).data('confirm-message') || 'Weet je zeker dat je deze actie uit wil voeren?');
    }
}
