export default {
    init() {
        const elements = document.querySelectorAll('.js-popup-menu');

        if (elements.length === 0) {
            return;
        }

        elements.forEach((element) => {
            element.addEventListener('click', this.onClick.bind(this));
        });

        document.addEventListener('click', (event) => {
            elements.forEach((element) => {
                const isInsideClick = element.contains(event.target);

                if (isInsideClick) {
                    return;
                }

                element.nextElementSibling.classList.remove('is-open');
            });
        });
    },

    onClick(event) {
        const element = event.currentTarget;

        element.nextElementSibling.classList.toggle('is-open');
    }
};
