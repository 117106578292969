export default class TableRowHandler {
    constructor(selector = '.js-toggleable-row') {
        this.rows = [...document.querySelectorAll(selector)];

        if (this.rows.length === 0) {
            return;
        }

        this.rows.forEach((row) => {
            row.addEventListener('click', (e) => {
                const row = e.currentTarget;
                const state = document.getElementById(row.dataset.for);

                row.classList.toggle('is-expanded');

                state.checked = !state.checked;
            });

            if (!row.classList.contains('has-error')) {
                return;
            }

            row.click();
        });
    }
}
