import $ from 'jquery';

export default class StringToHex {
    constructor(selector = '.js-stringToHex') {
        this._$stringToHex = $(selector);

        if (this._$stringToHex.length === 0) {
            return;
        }

        this._$stringToHex.each((index, element) => {
            const string = $(element).attr('data-string-to-hex');
            if (!string) {
                return;
            }
            const color = intToRGB(hashCode(string));
            const rStart = 0;
            const rEnd = 2;
            const gEnd = 4;
            const bEnd = 6;
            const r = parseInt(color.substring(rStart,rEnd), 16);
            const g = parseInt(color.substring(rEnd,gEnd), 16);
            const b = parseInt(color.substring(gEnd,bEnd), 16);
            $(element).css({
                'color': `#${color}`,
                'background-color': 'rgba(' + r + ',' + g + ',' + b + ',0.2)'
            });
        });
    }
}


function hashCode(str) {
    let hash = 0;
    const calc = 5;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << calc) - hash);
    }
    return 100 * hash;
}

function intToRGB(i) {
    const hashStart = 0x00FFFFFF;
    const hexLength = 6;
    const radix = 16;
    const c = (i & hashStart)
        .toString(radix)
        .toUpperCase();

    return '00000'.substring(0, hexLength - c.length) + c;
}
