import 'select2';
import $ from 'jquery';
export default class CommunityFilters {
    constructor(selector = '.js-community-filter') {
        const filterForm = document.querySelector(selector);
        if (!filterForm) {
            return;
        }
        this.selectors = {
            filters: {
                measure: '.js-community-filter-measure',
            },
        };

        this._initSelect2();
    }

    _initSelect2() {
        $(this.selectors.filters.measure).select2({
            language: {
                noResults: function () {
                    return 'Geen resultaten gevonden';
                },
            },
        });
        $(this.selectors.filters.measure).on('change', this._filter.bind(this));
    }

    _filter() {
        let url = new URL(window.location.href);

        for (const [filter, selector] of Object.entries(
            this.selectors.filters
        )) {
            if (filter !== null && filter !== '') {
                url.searchParams.set(
                    filter,
                    document.querySelector(selector).value
                );
            }
        }
        window.location.href = url;
    }
}
