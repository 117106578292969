import $ from 'jquery';
import modal from '../modal';
import { EditComment } from './edit';

export default class CommentFrom {
    constructor(selector = '.js-comment-form') {
        const $forms = $(selector);
        if ( !$forms.length ) {
            return;
        }

        $forms.on('submit', this._handleSubmit.bind(this));
    }

    _handleSubmit(e) {
        e.preventDefault();

        const $form = $(e.currentTarget);
        const $commentHolder = $('.js-comment-holder');
        const message = $form.find('textarea').val();
        const isPrivate = $form.find('.js-is-private-checkbox').is(':checked') ? 1 : 0;
        const chapter = $form.data('chapter');
        const $commentsCount = $('.js-comments-count');
        const commentsCount = parseInt($commentsCount.text());

        if ( !message.trim() ) {
            return alert('Je hebt nog geen opmerkingen toegevoegd.');
        }

        const data = { 'message' : message, 'isPrivate': isPrivate };

        if (chapter) {
            data.caption = chapter;
        }

        $form.find('.btn').addClass('btn--loading');

        $.ajax({
            method: 'POST',
            url: $form.attr('action'),
            data: JSON.stringify(data)
        }).done((data) => {
            $form.find('.btn').removeClass('btn--loading');
            $('.redactor-in').html('');

            if ($commentHolder.length === 0) {
                // @todo redirect to community?
                $form.parent().html('<p>Bedankt voor het versturen van je opmerkingen, wij nemen ze in behandeling!</p>');

                return;
            }

            if (commentsCount === 0) {
                $commentHolder.find('p').remove();
            }

            if ($commentsCount) {
                $commentsCount.html(commentsCount + 1);
            }

            const $newComment = $(data);
            $newComment.addClass('result-comment--animate');
            $commentHolder.append($newComment);

            const $modal = $newComment.find('.modal');
            const $editButton = $newComment.find('.js-comment-edit');

            if ($editButton) {
                $editButton.on('click', () => {
                    new EditComment($newComment);
                });
            }

            if ($modal) {
                modal.init();
            }

            if ($newComment) {
                $('html, body').animate({
                    scrollTop: $newComment.offset().top
                }, 1000); // eslint-disable-line no-magic-numbers
            }
        }).fail((err) => {
            console.error(err);
            alert('Er ging iets mis tijdens het verzenden van je opmerkingen. Probeer het later nog eens.');
        });
    }
}
