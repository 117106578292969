import $ from "jquery";
import "fh-redactor/lib/redactorIII/redactor";
import "fh-redactor/lib/redactorIII/langs/nl";
import modal from "../modal";
import FHRedactor from 'fh-redactor/build/fhRedactorIII';

export class EditComment {
  constructor(postElement) {
    this.post = postElement;
    this.editButton = this.post.find(".js-comment-edit");
    this.message = this.post.find(".js-comment-message");
    this.isPrivate = this.post.data("private");
    this.data = this.editButton.data();
    this.form = $('#form_' + this.data.commentId);

    this.createFormElements();

    if (this.post.hasClass("is-editable")) {
      return;
    }

    this.form.show();
    this.message.hide();

    this.initRedactor();
    this.initEventListeners();
  }

  createFormElements() {
    if (this.post.find(".community-post-edit-actions").length === 0) {
      this.submitButton = $('<button class="btn">Opslaan</button>');
      this.cancelButton = $(
        '<button class="btn btn-outline">Annuleren</button>'
      );
      this.buttonWrapper = $('<div class="community-post-edit-actions"></div>');
      this.buttonWrapper.append(this.submitButton, this.cancelButton);
      this.post.append(this.buttonWrapper);
    }
  }

  initRedactor() {
    $("[data-tool~=fh-redactor]").each((index, element) => {
      new FHRedactor(element, {
        toolbarFixed: false,
        buttons: ["bold", "italic", "lists", "link", "file", "image"],
      });
    });

    this.post.addClass("is-editable");
  }

  initEventListeners() {
    this.submitButton.on("click", () => {
      this.submitButton.addClass("btn--loading");
      const message = this.form.find('textarea').val();
      const isPrivate = !!this.form.find('.js-is-private-checkbox').is(':checked');
      const url = Routing.generate("community_comment_async_edit_controller", {
        slug: this.data.slug,
        comment_id: this.data.commentId, // eslint-disable-line camelcase
      });

      $.ajax({
        method: "PUT",
        url: url,
        data: JSON.stringify({
          'message': message,
          'isPrivate': isPrivate
        }),
      }).done((data) => {
        this.submitButton.removeClass("btn--loading");
        this.post.replaceWith(data);

        modal.init();
        init();
      });
    });

    this.cancelButton.on("click", () => {
      this.post.removeClass("is-editable");
      this.buttonWrapper.remove();
      this.form.hide();
      this.message.show();
    });
  }
}

function init() {
  const editButtons = $(".js-comment-edit");

  if (editButtons.length === 0) {
    return;
  }

  editButtons.on("click", onEditCommentClick.bind(this));
}

function onEditCommentClick(event) {
  const button = $(event.currentTarget);
  const post = button.closest(".community-post");

  new EditComment(post);
}

export default {
  init: init,
};
