import $ from 'jquery';

export default class MeasureContentHandler {
    constructor(selector = '.measure-content', url = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js', config = 'TeX-AMS_HTML') {
        this.$measureContent = $(selector);

        if ( !this.$measureContent.length ) {
            return;
        }

        let content = this.$measureContent.html();
        content = content.replace(/(((?:^|\n)\s*)[*•](.*))+/g, '<ul>$&\n</ul>');
        content = content.replace(/((?:<ul>\n?|\n)\s*)[*•](.*)/g, '$1<li>$2</li>');

        this.$measureContent.html(content);

        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `${url}?config=${config}`;
        document.getElementsByTagName('head')[0].appendChild(script);

        this._addEventHandlers();
    }

    _addEventHandlers() {
        this.$measureContent.find('.js-catalog-detail-link').on('click', this._linkToCatalogDetail.bind(this));
    }

    _linkToCatalogDetail(event) {
        event.preventDefault();
        const button = event.currentTarget;
        const target = $(button).attr("data-target");
        const tab = $(button).attr("data-tab");
        const $div = $(`#${target}`);
        const $checkbox = $div.find('input[type="checkbox"]');

        if ($checkbox.length) {
            $checkbox.prop('checked', true);

            const offsetTop = $div.offset().top;
            const scrollOffset = -80;
            $('html, body').animate({
                scrollTop: offsetTop + scrollOffset
            }, 400);
        }

        // open tab (js-tabs) if available
        if (!tab) {
            return;
        }
        const tabs = $div.find('.js-tabs');
        const $links = tabs.find(`a[href="#${tab}"]`);

        if ($links.length) {
            $links[0].click();
        }
    }
}
