import $ from 'jquery';

export default class MeasureBookHandler {
    constructor(selector = '.js-measure-book') {
        this.selector = selector;
        this.$popup = $(`${this.selector}-popup`);

        $(`${this.selector}-generate`).on('click', this.handleClickGenerate.bind(this));
    }

    handleClickGenerate(e) {
        const $measures = $('.measure-item'),
              $unchecked = $measures.filter(':not(.is-checked)');

        if ( !$unchecked.length ) {
            return;
        }

        e.preventDefault();
        this.$popup.find(`${this.selector}-popup-measures-unchecked`).text($unchecked.length);
        this.$popup.find(`${this.selector}-popup-measures-total`).text($measures.length);
        this.$popup.addClass('is-active');
    }
}
