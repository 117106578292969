export default class Navigation {
    constructor() {
        this._header = document.querySelector('.header');
        this._hamburger = document.querySelector('.js-hamburger');

        if (!this._hamburger) {
            return;
        }

        this._hamburger.addEventListener('click', this._onHamburgerClick.bind(this));
    }

    _onHamburgerClick(event) {
        event.preventDefault();
        const button = event.currentTarget;

        button.classList.toggle('is-active');
        this._header.classList.toggle('is-open');
    }
}
