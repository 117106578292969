import $ from 'jquery';

export default class Truncate {
    constructor(selector = '.js-truncate') {
        this.$selectors = $(selector);
        this.threshold = 130; // match .js-truncate max-height in _truncate.scss

        if (!this.$selectors.length) {
            return;
        }

        this.$selectors.each((_index, element) => {
            this._initTruncate(element);
        });

        function debounce(func, wait) {
            let timeout;
            return function() {
                const context = this, args = arguments;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait);
            };
        }

        $(window).resize(debounce(function() {
            this._reInitTruncate();
            
        }, 250).bind(this));
    }

    _reInitTruncate() {
        this.$selectors.each((_index, element) => {
            this._initTruncate(element);
        });
    }

    _initTruncate(elem) {
        const $elem = $(elem);
        const height = $elem.height();

        if (height < this.threshold) {
            this._removeTruncate($elem);
            return;
        }

        if ($elem.hasClass('is-truncated')) {
            // already truncated
            return;
        }

        $elem.addClass('is-truncated');
        $elem.addClass('is-closed');

        const $toggle = $(`<button class="btn-link truncate-toggle">Lees meer</button>`);
        $elem.append($toggle);

        $toggle.on('click', this._toggleTruncate.bind(this));
    }

    _removeTruncate($elem) {
        $elem.removeClass('is-truncated');
        $elem.removeClass('is-closed');
        $elem.find('.truncate-toggle').remove();
    }

    _toggleTruncate(event) {
        const button = event.currentTarget;
        const $truncate = $(button).closest('.js-truncate');

        if ($truncate.hasClass('is-closed')) {
            button.textContent = 'Lees minder';
        } else {
            button.textContent = 'Lees meer';
        }

        $truncate.toggleClass('is-closed');
    }
};
