import $ from 'jquery';

export default class TrackEvent {
    constructor(selector = '.js-track-event') {
        this.$selectors = $(selector);

        if (!this.$selectors.length) {
            return;
        }

        this.$selectors.each((_index, element) => {
            this._initTrackEvent(element);
        });
    }

    _initTrackEvent(element) {
        const $element = $(element);
        const data = $element.data();

        $element.on('click', () => {
            if ($element.hasClass('toggle-block-header-toggle-icon')) {
                const content = $element.closest(".block.catalog-detail").find(".toggle-block-content");

                data.trackEventData = {
                    ...data.trackEventData,
                    'action': !content.is(":visible") ? 'opened' : 'closed',
                }
            }

            $.ajax({
                method: 'POST',
                url: '/_mixpanel/track',
                xhrFields: {
                    withCredentials: true
                },
                data: JSON.stringify({
                    'name': data.trackEventName,
                    'data': {
                        url: window.location.href,
                        ...data.trackEventData
                    },
                })
            })
        });
    }
}
