import $ from 'jquery';

export default class PopupHandler {
    constructor(selector = '.js-popup') {
        const $popup = $(selector);

        $popup.find(`${selector}-close, ${selector}-backdrop`).on('click', () => {
            $popup.removeClass('is-active');
        });
    }
}
