import queryString from 'query-string';
import axios from 'axios';
import popupMenu from '../popupMenu';

const filterValues = queryString.parse(location.search, {
    arrayFormat: 'bracket',
});

class FilterForm {
    constructor(form) {
        this.form = form;
        this.name = this.form.name;
        this.checkboxes = this.form.querySelectorAll('input[type="checkbox"]');
        this.values = filterValues[this.name] ? filterValues[this.name] : [];

        this.initEventListeners();
    }

    initEventListeners() {
        this.checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', this.handleCheckbox.bind(this));
        });
    }

    handleCheckbox(event) {
        const { value, checked } = event.currentTarget;

        if (checked) {
            this.values.push(value);
            this.update();

            return;
        }

        const index = this.values.findIndex(
            (currentValue) => value === currentValue
        );
        this.values.splice(index, 1);
        this.update();
    }

    update() {
        const body = document.querySelector('body');

        body.classList.add('filter-is-loading');

        update(this.name, this.values, () => {
            body.classList.remove('filter-is-loading');
        });
    }
}

const update = (name, value, onComplete = () => {}) => {
    filterValues[name] = value;

    const qs = queryString.stringify(filterValues, {
        arrayFormat: 'bracket',
    });

    const url = `?${qs}`;
    const oldList = document.querySelector('.js-filter-list');

    history.replaceState(null, null, url);

    axios.get(url).then((response) => {
        const parser = new DOMParser();
        const newPage = parser.parseFromString(response.data, 'text/html');
        const newList = newPage.querySelector('.js-filter-list');

        oldList.replaceWith(newList);
        onComplete();
        popupMenu.init();
    });
};

const filterForm = {
    init() {
        const filterForms = document.querySelectorAll('.js-filter-form');

        if (filterForms.length === 0) {
            return;
        }

        filterForms.forEach((filterForm) => {
            new FilterForm(filterForm);
        });
    },
};

const sortForm = {
    init() {
        const select = document.querySelector('.js-filter-sort');

        if (!select) {
            return;
        }

        select.addEventListener('change', this.onChange.bind(this));
    },

    onChange(event) {
        update('order', event.currentTarget.value);
    },
};

export default {
    filter: filterForm,
    sort: sortForm,
};
