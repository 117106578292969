import $ from 'jquery';

export default class ExpandContentHandler {
    constructor(selector = '.js-expand-content') {
        const $contentBlocks = $(selector);

        $contentBlocks.each((index, contentBlock) => {
            const $contentBlock = $(contentBlock);

            $contentBlock.addClass('is-collapsed');
            $('<button class="btn btn-link">Lees verder</button>')
                .appendTo($contentBlock)
                .on('click', (e) => {
                    $contentBlock.removeClass('is-collapsed');
                    $(e.currentTarget).remove();
                });
        });
    }
}
