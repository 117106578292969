import 'select2';
import $ from 'jquery';

export default class CatalogSearch {
    constructor(selector = '.js-catalog-search') {
        this.$search = $(selector);
        if (!this.$search.length) {
            return;
        }

        this.path = this.$search.find('form').attr("data-path");
        this.select = this.$search.find('.js-catalog-search-select');
        this.initialValue = this.select.attr('data-initial-value');

        this.selectors = {
            result: '.js-catalog',
            options: {
                tgn: '.js-catalog-search-filter-tgn',
                active: '.js-catalog-search-filter-active',
                sorting: '.js-catalog-search-sorting',
                search: '.js-catalog-search-select',
            },
        };
        this._addEventHandlers();
        this._buildUrl();
        this._getSearchSuggestions();
    }

    _getSearchSuggestions() {
        const $select = this.select;
        const initialValue = this.initialValue;
        const initSelect2 = this._initSelect2;

        const addOptionsRecursively = (items, $select) => {
            items.forEach(function(item) {
                const $option = $('<option>').text(item.text).val(item.value).attr('data-type', item.type);

                if (item.value === initialValue) {
                    $option.attr('selected', true);
                }

                $select.append($option);
            });
        }

        const formatOptions = (data) => {
            return data.reduce((acc, levelItem) => {
                // Map the level item
                const levelMapped = {
                    type: levelItem.type,
                    level: null,
                    measure: null,
                    value: levelItem.searchTerm,
                    text: levelItem.searchTerm
                };
                
                // Push the level item to the accumulator
                acc.push(levelMapped);
        
                // Map the measure items
                const measures = levelItem.items.map(measureItem => {
                    const measureMapped = {
                        type: measureItem.type,
                        level: levelMapped.value,
                        measure: null,
                        value: measureItem.searchTerm,
                        text: measureItem.searchTerm
                    };
                    
                    // Map the variant items
                    const variants = measureItem.items.map(variantItem => ({
                        type: variantItem.type,
                        level: levelMapped.value,
                        measure: measureMapped.value,
                        value: variantItem.searchTerm,
                        text: variantItem.searchTerm
                    }));
        
                    // Add the measure and variants to the accumulator
                    return [measureMapped, ...variants];
                });
        
                // Flatten the measures array and add to the accumulator
                return acc.concat(...measures);
            }, []);
        }

        $.ajax({
            url: '/search-autocomplete',
            dataType: 'json',
            success: function(data) {
                const mappedData = formatOptions(data);

                addOptionsRecursively(mappedData, $select);
            },
          }).done(function() {
            initSelect2($select, initialValue);
          });
    }

    _initSelect2($select, initialValue) {
        const hasInitialValue = $select.find('option').filter(function() {
            return $(this).val() === initialValue;
        }).length > 0;

        if (!hasInitialValue) {
            // create custom tag option, when searching for a custom search term
            const $initialOption = $('<option>').text(initialValue).val(initialValue).data('type', 'tag').attr('selected', true);
            $select.prepend($initialOption);
        }

        $select.select2({
            tags: true,
            allowClear: true,
            placeholder: 'Zoek op levels, maatregelen of codes',
            templateResult: function (data) {
                if (!data.id || !data.element) {
                    return data.text;
                }

                const $element = $(data.element);
                const type = $element.data('type');

                let typeLabel = '';

                if (type === 'level') {
                    typeLabel = `<span class="catalog-search-input-option level">${data.text}</span>`;
                }
                if (type === 'measure') {
                    typeLabel = `<span class="catalog-search-input-option measure">${data.text}</span>`;
                }
                if (type === 'variant') {
                    typeLabel = `<span class="catalog-search-input-option variant">${data.text}</span>`;
                }
                if (type === 'tag') {
                    typeLabel = `<span class="catalog-search-input-option tag">${data.text}</span>`;
                }

                return $(`<span>${typeLabel}</span>`);
            },
            language: {
                inputTooShort: function (args) {
                    return `Voer minimaal ${args.minimum} karakters in`;
                },
                noResults: function () {
                    return 'Geen resultaten gevonden';
                },
                removeAllItems: function () {
                    return 'Verwijder zoekterm';
                },
                removeItem: function () {
                    return 'Verwijder zoekterm';
                },
            },
        });
    }

    _addEventHandlers() {
        this.$search.find('form').on('submit', this._search.bind(this));
        this.$search.find('select').on('change', this._search.bind(this));
    }

    _buildUrl() {
        const route = `${window.location.origin}${this.path}`;
        let url = new URL(route);

        for (const filter in this.selectors.options) {
            url.searchParams.delete(filter);
            let value = this.$search.find(this.selectors.options[filter]).val();
            if (value !== null && value !== '' && value !== undefined) {
                url.searchParams.set(
                    filter,
                    this.$search.find(this.selectors.options[filter]).val()
                );
            }
            this.$search
                .find(this.selectors.options[filter])
                .closest('.select')
                .toggleClass('active', value !== null && value !== '');
        }

        return url;
    }

    _search(e) {
        e.preventDefault();
        const url = this._buildUrl();

        location.replace(url);
    }
}
