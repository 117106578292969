import queryString from 'query-string';

export default {
    init() {
        const select = document.querySelector('.js-threads-list');

        if (!select) {
            return;
        }

        const qs = queryString.parse(location.search);

        select.addEventListener('change', (event) => {
            qs.order = event.currentTarget.value;

            window.location.href = `?${queryString.stringify(qs)}`;
        });
    }
};
