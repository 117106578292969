import $ from 'jquery';

export default class FHFileUpload {
    constructor(selector = '.js-file-upload') {
        this.$fileUploads = $(selector);
        if ( !this.$fileUploads.length ) {
            return;
        }

        require.ensure([], (require) => {
            require('fh-file-upload/scss/file-upload.scss');
            const fileUpload = require('../lib/fh-file-upload');

            fileUpload.default.init();
        });
    }
}
