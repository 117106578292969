import Flickity from 'flickity';

const LINK_ACTIVE_CLASS = 'is-active';
const TAB_ACTIVE_CLASS = 'tab-content--active';

class Tabs {
    constructor(tabHolder, options = {}) {
        this._holder = tabHolder;
        this._options = {
            updateHash: true,
            ...options,
        };
        this._links = [...this._holder.querySelectorAll('a')];
        this._targets = [];
        this._hash = window.location.hash;
        this._hashes = this._links.map((link) => link.getAttribute('href'));

        this._links.forEach((link) => {
            const target = document.getElementById(
                link.getAttribute('href').replace('#', '')
            );
            this._targets.push(target);

            link.addEventListener('click', this._toggleTabContent.bind(this));
        });

        if (this._options.updateHash && this._hash) {
            if (this._hashes.includes(this._hash)) {
                this._targets.forEach((target) => {
                    target.classList.remove(TAB_ACTIVE_CLASS);
                });
            }

            const hashElement = document.getElementById(
                this._hash.replace('#', '')
            );
            hashElement.classList.add(TAB_ACTIVE_CLASS);

            this._links.forEach((link) => {
                link.classList.remove(LINK_ACTIVE_CLASS);

                if (link.getAttribute('href') === this._hash) {
                    link.parentNode.classList.add('flickity-active');
                    link.classList.add(LINK_ACTIVE_CLASS);
                }
            });
        }

        this._resize();
        window.addEventListener('resize', this._resize.bind(this));
    }

    _toggleTabContent(event) {
        event.preventDefault();
        const currentLink = event.currentTarget;
        const href = currentLink.getAttribute('href');
        const id = href.replace('#', '');
        const target = document.getElementById(id);

        this._links.forEach((link) => {
            link.classList.remove(LINK_ACTIVE_CLASS);
        });

        this._targets.forEach((target) => {
            target.classList.remove(TAB_ACTIVE_CLASS);
        });

        currentLink.classList.add(LINK_ACTIVE_CLASS);
        target.classList.add(TAB_ACTIVE_CLASS);

        if (!this._options.updateHash) {
            return;
        }

        window.location.hash = id;
    }

    _resize() {
        const offsets = [];
        this._holder.classList.remove('has-no-tab');

        this._links.forEach((link) => {
            const clientRect = link.getBoundingClientRect();

            offsets.push(clientRect.top);
        });

        if (this.flickity) {
            this.flickity.destroy();
        }

        if (!offsets.every((val) => val === offsets[0])) {
            this._holder.classList.add('tabs-carousel');
            this._createTabCarousel();
        }
    }

    _createTabCarousel() {
        this.flickity = new Flickity(this._holder.querySelector('ul'), {
            freeScroll: true,
            contain: true,
            pageDots: false,
            groupCells: true,
            initialIndex: '.flickity-active',
        });
    }
}

export default {
    init() {
        const tabHolders = document.querySelectorAll('.js-tabs');

        if (tabHolders.length === 0) {
            return;
        }

        tabHolders.forEach((tabHolder) => {
            const dataset = tabHolder.dataset;
            const options = {};

            if (dataset.updateHash) {
                options.updateHash = dataset.updateHash === '1';
            }

            new Tabs(tabHolder, {
                ...options,
            });
        });
    },
};
