import $ from 'jquery'

export default class TutorialHandler {
    constructor(selector = '.js-tutorial') {
        this.currentIndex = 0

        const $popup = $(selector)
        $popup.addClass('is-active')

        this.slides = $popup.find('.tutorial-slide')
        this.dots = $popup.find(`${selector}-dot`)

        this._setActiveSlide(0) // init

        $popup.find(`${selector}-close`).on('click', (e) => {
            e.preventDefault()

            // todo generate route
            const url = Routing.generate('user_tutorial_shown_async')

            $.ajax({
                method: 'POST',
                url: url,
                data: JSON.stringify({
                    message: true,
                }),
            }).done(() => {
                $popup.removeClass('is-active')
            })
        })

        $popup.find(`${selector}-next-slide`).on('click', (e) => {
            e.preventDefault()

            this._setActiveSlide(this.currentIndex + 1)
        })
    }

    _setActiveSlide(index) {
        const $slides = $(this.slides)
        const $dots = $(this.dots)

        $slides.removeClass('active')
        $dots.removeClass('active')
        $slides.eq(index).addClass('active')
        $dots.eq(index).addClass('active')

        this.currentIndex = index // save active index
    }
}
