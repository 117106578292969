import tippy from 'tippy.js';

export default {
    init() {
        const tooltips = document.querySelectorAll('[data-tooltip]');

        if (tooltips.length === 0) {
            return;
        }

        tooltips.forEach((tooltip) => {
            const data = tooltip.dataset;

            tippy(tooltip, {
                content: data.tooltip,
                theme: 'light-border'
            });
        });
    }
};
