import $ from 'jquery';

export default class ToggleableAjaxHandler {
    constructor(selector = '.js-toggleable-ajax') {
        $(selector).on('change', this.handleChange.bind(this));
    }

    handleChange(e) {
        e.preventDefault();
        const $toggleable = $(e.currentTarget),
              $label = $toggleable.next('label');

        $toggleable.prop('checked', !$toggleable.prop('checked'));
        if ( $label.hasClass('is-loading') ) {
            return;
        }

        $label.addClass('is-loading');
        $.get($toggleable.data('url')).done((response) => {
            $toggleable.prop('checked', response.checked);
        }).always(() => {
            $label.removeClass('is-loading');
            $toggleable.closest('tr').toggleClass('is-checked', $toggleable.prop('checked'));
        }).fail(console.error);
    }
}
