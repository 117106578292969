export default {
    init() {
        this._accordions = document.querySelectorAll('.js-accordion');

        if (this._accordions.length === 0) {
            return;
        }

        this._accordions.forEach((accordion) => {
            if (accordion.classList.contains('has-no-click')) {
                return;
            }

            accordion.addEventListener('click', this._openAccordion.bind(this));
        });
    },

    _openAccordion(event) {
        const button = event.currentTarget;

        button.parentNode.classList.toggle('is-open');
    }
};
