import ToggleableAjaxHandler from './toggleable-ajax-handler';

export default class Toggle {
    constructor() {
        this._initFormSpinner();
        this._initResult();
    }

    _initFormSpinner() {
        this._toggleFormSpinner = document.querySelector('.js-toggle-form-spinner');

        if (!this._toggleFormSpinner) {
            return;
        }

        this._toggleFormSpinner.addEventListener('click', this._addLoadingClass.bind(this));
    }

    _initResult() {
        const resultToggle = document.querySelector('.js-toggle-result');

        if (!resultToggle) {
            return;
        }

        const target = document.querySelector('.result__target');

        resultToggle.addEventListener('click', (event) => {
            event.preventDefault();
            event.currentTarget.classList.toggle('is-toggled');
            target.classList.toggle('is-visible');

            if (!target.classList.contains('is-visible')) {
                return;
            }

            new ToggleableAjaxHandler;
        });

        const hasErrors = [...target.querySelectorAll('.has-error')];

        if (hasErrors.length === 0) {
            return;
        }

        resultToggle.click();
    }

    _addLoadingClass(event) {
        const button = event.currentTarget;

        button.classList.add('btn--loading');
    }
}
