import { mapKeys } from 'lodash';

export default class Chart {
    constructor(selector = '.js-chart') {
        this._selector = document.querySelector(selector);

        if (!this._selector) {
            return;
        }

        this._data = JSON.parse(this._selector.dataset.json);

        require.ensure([], () => {
            const ChartJS = require('chart.js');

            this._chart = new ChartJS(this._selector, {
                type: 'scatter',
                data: {
                    datasets: this._getDatasets()
                },
                options: {
                    responsive: true,
                    scales: {
                        xAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Verplaatsing (mm)'
                            }
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Capaciteit (N)'
                            }
                        }]
                    }
                }
            });
        });
    }

    _getDatasets() {
        const options = {
            showLine: true,
            fill: false,
            lineTension: 0
        };

        return this._data.map((dataset) => {
            const normalizedDataset = mapKeys(dataset, (value, key) => {
                if (key === 'points') {
                    return 'data';
                }

                return key;
            });

            const color = `#${Math.random().toString(16).slice(-3)}`; // eslint-disable-line no-magic-numbers

            this._renderCurveExplanation(dataset, color);

            return Object.assign(normalizedDataset, {
                borderColor: color,
                backgroundColor: color,
            }, options);
        });
    }

    _renderCurveExplanation(dataset, color) {
        if (!dataset.explanation) {
            return;
        }

        const template = `
            <div class="chart-explanation">
                <div class="chart-explanation-type" style="background-color: ${color}"></div>
                <div class="chart-explanation-label">${dataset.label}</div>
                <div class="chart-explanation-description">${dataset.explanation}</div>
            </div>
        `;

        this._selector.insertAdjacentHTML('afterend', template);
    }
}
