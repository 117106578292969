import { lockBody, unlockBody } from '../helpers/lockBody';

export default {
    init() {
        this.initFormToggle();
        this.initFilterMenuToggle();
    },

    initFormToggle() {
        const toggles = document.querySelectorAll('.js-filter-toggle');

        if (toggles.length === 0) {
            return;
        }

        toggles.forEach((toggle) => {
            toggle.addEventListener('click', (event) => {
                event.currentTarget.classList.toggle('is-toggled');
            });
        });
    },

    initFilterMenuToggle() {
        const toggle = document.querySelector('.js-filter-menu-toggle');

        if (!toggle) {
            return;
        }

        const filter = document.querySelector('.filter');
        const backdrop = document.querySelector('.filter-backdrop');
        const toggleFilter = () => {
            filter.classList.toggle('is-open');

            if (filter.classList.contains('is-open')) {
                lockBody();

                return;
            }

            unlockBody();
        };

        toggle.addEventListener('click', toggleFilter);
        backdrop.addEventListener('click', toggleFilter);
    }
};
