import $ from 'jquery';

export default class NotificationHandler {
    constructor(selector = '.notification', closeSelector = '.js-notification-close') {
        this.selector = selector;
        this.closeSelector = closeSelector;

        $(document).on('click', this.closeSelector, this._handleClose.bind(this));
    }

    _handleClose(e) {
        const $notification = $(e.currentTarget).closest(this.selector),
              $notifications = $notification.parent();

        // Remove this notification
        $(e.currentTarget).closest(this.selector).remove();

        // Make sure to clear container if no more notifications exist
        if ( !$notifications.children().length ) {
            $notifications.remove();
        }
    }
}
